<template>
  <v-tooltip
    :key="source"
    :disabled="noTooltip"
    :color="source === 'unknown' ? 'error' : null"
    top
  >
    <template #activator="{ on }">
      <span v-on="on">
        <i
          :class="['fa-fw', sourceObject.icon]"
          :style="'font-size:' + size + 'px'"
          v-on="on"
        />
      </span>
    </template>
    {{ sourceObject.text }}
  </v-tooltip>
</template>
<script>
  export default {
    props: {
      source: {
        type: String,
        default: 'unknown',
      },
      size: {
        type: Number,
        default: 16,
      },
      noTooltip: Boolean,
    },
    computed: {
      sourceObject () {
        return this.$sources.find(x => x.value === this.source.toLowerCase()) || {}
      },
    },
  }
</script>
